<template>
  <section class="userManagement">
    <prodige-title>
      <template #default>
        {{ $t('userManagement.title.userManagement') }}
      </template>
      <template #buttons>
        <router-link
          class="bg-green rounded-md py-1 px-4 text-white"
          :to="{ name: 'UserCreate' }"
        >
          {{ $t('userManagement.action.addNewUser') }}
        </router-link>
      </template>
    </prodige-title>

    <prodige-search :search="searchInstance" @searchResults="getResults" />

    <section class="mt-8">
      <prodige-export-button />
      <prodige-table :striped="true" @order="searchInstance.recoverFilter()">
        <template #colgroup>
          <col class="w-1/6" />
          <col class="w-1/6" />
          <col class="w-1/6" />
          <col class="w-1/6" />
          <col class="w-1/6" />
          <col class="w-1/6" />
        </template>
        <template #thead>
          <prodige-th sortable name="last_name" class="whitespace-no-wrap">
            {{ $t('userManagement.text.lastname') }}
          </prodige-th>
          <prodige-th sortable name="first_name" class="whitespace-no-wrap">
            {{ $t('userManagement.text.firstname') }}
          </prodige-th>
          <prodige-th sortable name="email" class="whitespace-no-wrap">
            {{ $t('userManagement.text.email') }}
          </prodige-th>
          <prodige-th sortable name="profile_id" class="whitespace-no-wrap">
            {{ $t('userManagement.text.profile') }}
          </prodige-th>
          <prodige-th sortable name="createdAt" class="whitespace-no-wrap">
            {{ $t('userManagement.text.creationDate') }}
          </prodige-th>
          <prodige-th sortable name="status_id" class="whitespace-no-wrap">
            {{ $t('userManagement.text.enabled') }}
          </prodige-th>
        </template>
        <router-link
          v-for="(user, id) in searchResults"
          :key="`user-${id}`"
          :to="{ name: 'UserEdit', params: { id: user.id } }"
          tag="tr"
          class="ogg:bg-light-grey text-13 border-b"
        >
          <prodige-td align="top" class="py-3 px-6">
            {{ user.last_name }}
          </prodige-td>
          <prodige-td align="top" class="py-3 px-6">
            {{ user.first_name }}
          </prodige-td>
          <prodige-td align="top" class="py-3 px-6">
            {{ user.email }}
          </prodige-td>
          <prodige-td align="top" class="py-3 px-6">
            {{ user.Profile.label }}
          </prodige-td>
          <prodige-td align="top" class="py-3 px-6">
            {{ new Date(user.createdAt) | date('dd/MM/yyyy') }}
          </prodige-td>
          <prodige-td>
            <ab-checkbox
              disabled
              name="id"
              :value="user.status_id === 1 ? true : false"
              class="inactive"
            />
          </prodige-td>
        </router-link>
      </prodige-table>
      <paginate
        v-if="searchResults.length"
        v-model="currentPage"
        :page-count="pageCount(count, searchInstance.limit)"
        prev-class="invisible"
        next-class="invisible"
        prev-text=""
        next-text=""
        :container-class="'prodige-pagination flex w-full mt-8 leading-3 item-center'"
      />
    </section>
  </section>
</template>

<script>
import { mapState } from 'vuex'

import Paginate from 'vuejs-paginate'
import { AbCheckbox } from '@ab/material-components'
import { dateFilter } from 'vue-date-fns'

import pagination from '../../mixins/pagination.js'
const {
  Search,
  FieldType,
  searchMixin,
  EventBus,
} = require('../../mixins/search')

// const { Search, FieldType, default: searchMixin, EventBus } = search;

export default {
  name: 'UserManagementList',
  components: {
    Paginate,
    AbCheckbox,
  },
  filters: {
    date: dateFilter,
  },
  mixins: [pagination, searchMixin],
  data() {
    return {
      searchInstance: new Search('User', this.$router),
    }
  },
  computed: {
    ...mapState({
      users: (state) => state.userManagement.users,
      users_counter: (state) => state.userManagement.users_counter,
    }),
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      const status = await this.$store.dispatch('getAllUserStatus')
      const profile = await this.$store.dispatch('getProfiles')
      // Nom
      // Prénom
      // Profil
      // Date de création
      // Statut

      if (status && profile) {
        this.searchInstance.setDefaultAssociations(['Status', 'Profile'])

        this.searchInstance.addField({
          name: 'lastname',
          type: FieldType.FreeSearch,
          label: this.$t('userManagement.text.lastname'),
          searchKey: 'last_name',
        })
        this.searchInstance.addField({
          name: 'fistname',
          type: FieldType.FreeSearch,
          label: this.$t('userManagement.text.firstname'),
          searchKey: 'first_name',
        })
        this.searchInstance.addField({
          name: 'profile',
          type: FieldType.ObjectList,
          label: this.$t('userManagement.text.profile'),
          data: profile,
          keyAsValue: 'id',
          keyAsLabel: 'label',
          searchKey: 'Profile.id',
        })
        this.searchInstance.addField({
          name: 'createdAt',
          type: FieldType.DateInterval,
          label: this.$t('userManagement.text.creationDate'),
          searchKey: 'createdAt',
        })
        this.searchInstance.addField({
          name: 'status',
          type: FieldType.ObjectList,
          label: this.$t('userManagement.text.status'),
          data: status,
          keyAsValue: 'id',
          keyAsLabel: 'label',
          searchKey: 'Status.id',
        })
        this.hideSpinner()
      } else {
        this.$store.dispatch('addMessage', {
          key: 'getUsersError',
          params: { display: 'userManagement.error.getUsers', error: true },
        })
        this.$router.go(-1)
      }
    },
    getData(isNewSearch) {
      EventBus.$emit('getData')
    },
  },
}
</script>

<style scoped></style>
